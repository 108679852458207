import { CassiusImg, AdityaImg, EshaaImg } from "../utils/imageProvider";

const testimonialsData = [
  {
    imageUrl: EshaaImg,
    rating: 1,
    title: "Yeh toh upgrade ka bhi upgrade hai 🐐",
    description: `The new design is so cool and app has become more smooth. The FamApp has everything I need in one place.`,
    author: "Eshaa",
    source: "Play store",
    reviewDate: "22nd June 2020",
  },
  {
    imageUrl: AdityaImg,
    rating: 2,
    title: "Love the concept of spending account 💖",
    description: `The idea is so cool. I can use my spending account to make daily payments - and keep it separate from my savings now!`,
    author: "Aditya",
    source: "Instagram",
    reviewDate: "21st Aug 2020",
  },
  {
    imageUrl: CassiusImg,
    rating: 3,
    title: "My payment took 0.6 seconds! ⚡",
    description:
      "I was at a shop, and I needed to make a payment in hurry. It was honestly the fastest payment I’ve ever done.",
    author: "Cassius",
    source: "Play store",
    reviewDate: "8th July 2020",
  },
];

export default testimonialsData;
