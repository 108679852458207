// Modules
import React, { useRef, useState } from "react";
import ReactCardCarousel from "react-card-carousel";
import { Row, Col } from "react-bootstrap";

// Assets
import testimonialsData from "../../constants/testimonialsData";

// Styles
import styles from "./TestimonialCards.module.scss";

const Testimonial = ({ type, isTabletView }) => {
  const cardCaraouselRef = useRef();
  const [currentIndex, setCurrentIndex] = useState(0);
  let testimonials = testimonialsData;
  if (type !== undefined) {
    testimonials.filter((testimonial) =>
      type === "Parents"
        ? testimonial.age === "Parent"
        : testimonial.age !== "Parent"
    );
  }

  return (
    <>
      {isTabletView ? (
        <div className={styles.mob_scrollable_container}>
          <div className={`${styles.testimonial_card_container}`}>
            {(testimonials || []).map((testimonial, index) => (
              <div
                key={index}
                className={styles.testimonial_card}
                style={{ backgroundColor: "transparent" }}
              >
                <div
                  className={styles.profile_image_container}
                  style={{
                    backgroundImage: `url(${testimonial.imageUrl})`,
                  }}
                />
                <div className={styles.text_content}>                
                  <h6
                    className={`${styles.testimonial_headline} light_grey_text`}
                  >
                    {testimonials[index].title}
                  </h6>
                  <p className={`${styles.testimonial_description}`}>
                    {testimonials[index].description}
                  </p>
                  <div className={`light_grey_text ${styles.source_info}`}>
                    <p className={`${styles.author_info} light_black_text`}>
                      {testimonials[index].author}
                    </p>                 
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <Row className={`${styles.testimonial_container}`}>
          <Col
            xs={12}
            lg={6}
            className={`${styles.testimonial_cards_container}`}
          >
            <ReactCardCarousel
              ref={cardCaraouselRef}
              autoplay={true}
              autoplay_speed={8000}
              spread="narrow"
              afterChange={() => {
                setCurrentIndex(cardCaraouselRef.current.getCurrentIndex());
              }}
            >
              {testimonials.map((testimonial, index) => (
                <div
                  key={index}
                  className={`${styles.testimonial_card}`}
                  style={{
                    backgroundImage: `url(${testimonial.imageUrl})`,
                  }}
                ></div>
              ))}
            </ReactCardCarousel>
          </Col>
          <Col
            xs={12}
            lg={6}
            className={`${styles.testimonial_text_container} text-left`}
          >         
            <h6
              className={`subheading_text ${styles.testimonial_headline} light_grey_text`}
            >
              {testimonials[currentIndex].title}
            </h6>
            <p
              className={`${styles.testimonial_description} subparagraph_text`}
            >
              {testimonials[currentIndex].description}
            </p>
            <p
              className={`${styles.author_info} paragraph_text light_black_text`}
            >
              {testimonials[currentIndex].author}
            </p>         
          </Col>
        </Row>
      )}
    </>
  );
};

export default Testimonial;
