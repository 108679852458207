/* eslint-disable @next/next/no-img-element */
// Modules
import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { isAndroid, isIOS, isMacOs } from "react-device-detect";

// Components
import Footer from "../components/footer/Footer";
import QrCode from "src/components/QrCode/QrCode";
import Header from "../components/header/HeaderContainer";
import FamCoinsPerks from "../components/famCoinsPerks/FamCoinsPerks";
import Testimonial from "../components/testimonialCards/TestimonialCards";
import ButtonContainer from "../components/buttonContainer/ButtonContainer";

// Styles
import styles from "../styles/home.module.scss";

// Import Utils
import { Media } from "../utils/mediaQueryHandler";

// Assets
import {
  FamXBanner,
  upiImage,
  scanPayUpi,
  recharge,
  personalizedThemes,
  hiringImage,
  supportChatGif,
  HomeScreenPCVisual,
  HomeScreenMobVisual,
  // SpendingAccountVisual,
  SpendingAccountImage,
  userTrustImage,
  famcoinRewards,
  elevationImage,
  sequoiaImage,
  vhImage,
  gcImage,
  ycImage,
  gfcImage,
  rocketshipImage,
  greenoaksImage,
  TrioLogo,
  VisaLogo,
  RupayLogo,
  UPILogo,
  FamXTeens,
  FamXCardTitle,
  FamXCard,
  Classic,
  HigherDepositLimits,
  Ultra,
} from "../utils/imageProvider";

const institutionInvestors = [
  ["Elevation Capital", elevationImage, "https://www.elevationcapital.com/"],
  ["Sequoia", sequoiaImage, "https://www.sequoiacap.com/india/"],
  ["Venture Highway", vhImage, "http://venturehighway.vc/"],
  ["General Catalyst", gcImage, "https://www.generalcatalyst.com/"],
  ["Y Combinator", ycImage, "https://www.ycombinator.com/"],
  [
    "Global Founders Capital",
    gfcImage,
    "https://www.globalfounderscapital.com//",
  ],
  ["Rocketship VC", rocketshipImage, "https://www.rocketship.vc/"],
  ["GreenOaks", greenoaksImage, "http://www.greenoakscap.com/"],
];

const Home = () => {
  const [url, setUrl] = useState(
    "https://play.google.com/store/apps/details?id=com.fampay.in&hl=en_IN&gl=US"
  );

  useEffect(() => {
    const getUPIClicked = () => {
      if (isAndroid) {
        setUrl(
          "https://play.google.com/store/apps/details?id=com.fampay.in&hl=en_IN&gl=US"
        );
      } else if (isIOS || isMacOs) {
        setUrl(
          "https://apps.apple.com/in/app/fampay-upi-payments-for-teens/id1499806454"
        );
      } else {
        setUrl(
          "https://play.google.com/store/apps/details?id=com.fampay.in&hl=en_IN&gl=US"
        );
      }
    };
    getUPIClicked();
  }, []);

  return (
    <>
      <Container fluid>
        <Header url={url} />
        <Media lessThan="lg">
          <Row className="header_section_background">
            <Col>
              <video
                autoPlay
                muted
                loop
                style={{
                  display: "block",
                  height: "100%",
                  position: "absolute",
                  left: "50%",
                  top: "45%",
                  transform: "translate(-50%, -50%)",
                  width: "100%",
                  zIndex: "-1",
                }}
              >
                <source src={HomeScreenMobVisual} type="video/mp4" />
              </video>
              <div className={`${styles.mob_header_content_container}`}>
                <img
                  alt="FamX"
                  src={FamXBanner}
                  style={{ marginBottom: "12px" }}
                ></img>
                <h1 className="heading_text white_text">spending account</h1>
                <div className={`${styles.heading}`}>
                  <div className={`${styles.heading_container}`}>
                    <p
                      className={`heading_text white_text ${styles.heading_container_text}`}
                    >
                      for{" "}
                    </p>
                    <div
                      className={`primary_orange_text ${styles.heading_container_list}`}
                    >
                      <div className={`${styles.heading_container_list_item}`}>
                        teens
                      </div>
                      <div className={`${styles.heading_container_list_item}`}>
                        adults
                      </div>
                      <div className={`${styles.heading_container_list_item}`}>
                        everyone
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`${styles.buttons}`}>
                  <ButtonContainer
                    buttonText={"Open account"}
                    url={url}
                    wrapStyle={{
                      width: "100%",
                      padding: ".8rem 2.5rem",
                      backgroundColor: "#FBAF03",
                      color: "#1c1c1c",
                      fontSize: "1.2rem",
                      fontWeight: "600",
                    }}
                    activeStatus
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Media>
        <Media greaterThanOrEqual="lg">
          <Row className="header_section_background">
            <Col>
              <video
                autoPlay
                muted
                loop
                style={{
                  display: "block",
                  height: "90%",
                  position: "absolute",
                  left: "10%",
                  top: "0",
                  width: "100%",
                  zIndex: "-1",
                }}
              >
                <source src={`${HomeScreenPCVisual}`} type="video/mp4" />
              </video>
              <div className={`${styles.header_content_container}`}>
                <img src={FamXBanner} alt="banner" style={{ width: 220 }}></img>
                <h1
                  className="heading_text white_text"
                  style={{ margin: "16px 0px 0px" }}
                >
                  spending account
                </h1>
                <div className={styles.heading}>
                  <div className={`${styles.heading_container}`}>
                    <p
                      className={`heading_text white_text ${styles.heading_container_text}`}
                    >
                      for
                    </p>
                    <div
                      className={`primary_orange_text ${styles.heading_container_list}`}
                    >
                      <div className={`${styles.heading_container_list_item}`}>
                        teens
                      </div>
                      <div className={`${styles.heading_container_list_item}`}>
                        adults
                      </div>
                      <div className={`${styles.heading_container_list_item}`}>
                        everyone
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`${styles.btns}`}>
                  <ButtonContainer
                    buttonText={"Open Account"}
                    url={
                      "https://play.google.com/store/apps/details?id=com.fampay.in&hl=en_IN&gl=US"
                    }
                    wrapStyle={{
                      backgroundColor: "#FBAF03",
                      color: "#1c1c1c",
                      marginRight: "20px",
                      fontSize: "1.5rem",
                      fontWeight: "600",
                    }}
                    activeStatus
                  />
                </div>
              </div>
              <div className={styles.bottom_links_container}>
                <img
                  src={userTrustImage}
                  style={{ height: "6rem" }}
                  alt="10M+ Registered Users"
                />
                <hr />
                <div className={styles.poweredBy}>Powered by</div>
                <img style={{ height: 30 }} src={TrioLogo} alt="Trio" />
                <img style={{ height: 26 }} src={VisaLogo} alt="Visa" />
                <img style={{ height: 30 }} src={RupayLogo} alt="Rupay" />
                <img style={{ height: 36 }} src={UPILogo} alt="UPI" />
              </div>
            </Col>
          </Row>
        </Media>
      </Container>
      <Container fluid className="dark_bg below_header_container">
        <Media lessThan="lg">
          <div className={`${styles.bottom_links_container_mobile}`}>
            <div className={styles.wrap} style={{ padding: "1rem 0px" }}>
              <img src={userTrustImage} alt="10M+ Registered Users" />
            </div>
            <div className={styles.poweredBy}>Powered by</div>
            <div className={styles.wrap} style={{ marginBottom: 26 }}>
              <img style={{ width: 55 }} src={TrioLogo} alt="Trio" />
              <img style={{ width: 70 }} src={VisaLogo} alt="Visa" />
              <img style={{ width: 70 }} src={RupayLogo} alt="Rupay" />
              <img style={{ width: 70 }} src={UPILogo} alt="UPI" />
            </div>
          </div>
        </Media>

        <Container className="light_grey_text extra_sides_padding">
          <Row className="section_vertical_spacing">
            <Col lg={6} style={{ textAlign: "center" }}>
              {/* <video
                autoPlay
                muted
                loop
                style={{ height: 600, maxWidth: "100%" }}
              >
                <source src={SpendingAccountVisual} type="video/mp4" />
              </video> */}
              <img
                src={SpendingAccountImage}
                style={{ height: 600, maxWidth: "100%" }}
                alt="spending-account-img"
                className={styles.all_transaction_image}
              />
            </Col>
            <Col lg={6}>
              <div>
                <h1 className="heading_text heading_text_sm white_text">
                  One account{" "}
                  <span className="primary_orange_text">
                    for all your spends
                  </span>
                </h1>
                <ul className="paragraph_text">
                  <li>Get your own FamX card & UPI ID</li>
                  <li>Enable quick payments with your fingerprint/Face ID</li>
                  <li>Easily track, manage and budget your expenses</li>
                  <li>Keep your bank statements clean & clutter-free</li>
                </ul>
                <br />
              </div>
            </Col>
          </Row>
        </Container>
        <Row
          className={`full_width_section section_vertical_spacing light_grey_text ${styles.famcard_section}`}
          style={{ background: "#000" }}
        >
          <Col lg={6} className="section_image_container section_right_image">
            <img
              className={` ${styles.famcard_image}`}
              src={FamXTeens}
              alt="FamCard"
            />
          </Col>
          <Col lg={6}>
            <div>
              <h1 className="heading_text heading_text_sm">
                <span className="primary_orange_text">
                  Your first and forever{" "}
                </span>
                <span className="white_text">account</span>
              </h1>
              <ul className="paragraph_text">
                <li>Make payments without a bank account</li>
                <li>Get a customised UPI & card as a teen</li>
                <li>Open for everyone ages 11+</li>
              </ul>
            </div>
          </Col>
        </Row>
        <Media lessThan="lg">
          <div className={`${styles.mob_upi}`}>
            <h1 className="heading_text_sm white_text">
              UPI payments that take less than{" "}
              <span className="primary_orange_text">2 seconds</span>
            </h1>
            <img
              src={scanPayUpi}
              alt="scan and pay"
              className={`${styles.upi_img_mob}`}
            />
            <p className="light_grey_text">
              Scan & Pay with your customised UPI ID
            </p>
            <img
              src={personalizedThemes}
              alt="scan and pay"
              className={`${styles.upi_img_mob}`}
            />
            <p className="light_grey_text">
              Choose personalized QR themes to match your style
            </p>
            <img
              src={recharge}
              alt="scan and pay"
              className={`${styles.upi_img_mob}`}
            />
            <p className="light_grey_text">
              Reacharge, pay bills, shop online, and more
            </p>
          </div>
        </Media>
        <Media greaterThanOrEqual="lg">
          <Container className="grey_text extra_sides_padding">
            <Row>
              <Col
                lg={6}
                className="section_image_container section_right_image"
              >
                <img
                  className={`${styles.upi_img} image_content`}
                  src={upiImage}
                  alt="upi"
                />
              </Col>
              <Col lg={6}>
                <div>
                  <h1 className="heading_text white_text">
                    UPI payments that go through in{" "}
                    <span className="primary_orange_text">seconds</span>
                  </h1>
                  <ul className="paragraph_text light_grey_text">
                    <li>Scan & Pay anywhere with your customised UPI ID</li>
                    <li>Choose from a range of fun QR themes</li>
                    <li>
                      Recharge, pay bills and shop online, all within the app
                    </li>
                  </ul>
                  <ButtonContainer
                    buttonText={"Get your own UPI ID"}
                    url={url}
                    wrapStyle={{
                      backgroundColor: "#FBAF03",
                      color: "#1c1c1c",
                      marginRight: "20px",
                      marginTop: "2rem",
                      fontSize: "1.5rem",
                      fontWeight: "600",
                    }}
                    activeStatus
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </Media>
        <Media lessThan="lg">
          <Row
            className={`full_width_section section_vertical_spacing ${styles.mob_famcoins_perks_section}`}
          >
            <Col>
              <h1
                style={{ paddingBottom: "0.5rem", textAlign: "center" }}
                className="heading_text_sm white_text"
              >
                The <span className="primary_orange_text">most rewarding </span>
                <br />
                spending account
              </h1>
              <p
                style={{
                  maxWidth: "100%",
                  padding: "0 10% 0 10%",
                  textAlign: "center",
                }}
                className={`${styles.description_text} paragraph_text`}
              >
                Cashbacks, discounts, and offers on your favourite brand.
              </p>
              <FamCoinsPerks isTabletView={true} />
            </Col>
          </Row>
        </Media>
        <Media greaterThanOrEqual="lg">
          <Row
            className={`full_width_section section_vertical_spacing ${styles.famcoins_perks_section}`}
          >
            <img
              src={famcoinRewards}
              alt="rewards"
              style={{
                display: "block",
                height: "100%",
                position: "absolute",
                left: "0%",
                top: "0",
                zIndex: "-1",
              }}
            />
            <Col className={`${styles.rewards_content}`}>
              <h1
                style={{ paddingBottom: "2rem" }}
                className="heading_text white_text"
              >
                The <span className="primary_orange_text">most rewarding</span>
                <br />
                spending account
              </h1>
              <FamCoinsPerks />
            </Col>
          </Row>
        </Media>

        <Container className="grey_text extra_sides_padding">
          <Row>
            <Col
              lg={6}
              xs={12}
              className="section_image_container section_right_image"
            >
              <img
                src={FamXCard}
                alt="upi"
                style={{ maxHeight: 650, maxWidth: "100%" }}
              />
            </Col>
            <Col
              lg={6}
              xs={12}
              style={{ display: "flex", alignItems: "center" }}
            >
              <div>
                <h1 className="heading_text_sm white_text">
                  <img
                    width={350}
                    src={FamXCardTitle}
                    alt="FamX Card Title"
                  ></img>
                </h1>
                <ul className="paragraph_text light_grey_text">
                  <li>Numberless card that keeps your info safe</li>
                  <li>Quick and secure payments with Tap & Pay</li>
                  <li>Design your own card with doodles</li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
        <h1
          className="heading_text white_text text-center"
          style={{ margin: "140px 20px" }}
        >
          Memberships
          <span className="primary_orange_text"> tailor-made </span>
          for your needs
        </h1>
        <Row
          className={styles.custom_plans}
          style={{ maxWidth: 1200, margin: "0 auto" }}
        >
          <Col lg={4} style={{ display: "flex" }}>
            <img
              style={{
                width: "370px",
                maxWidth: "90%",
                margin: "0 auto 40px",
              }}
              src={Classic}
              alt="Hiring"
            />
          </Col>
          <Col lg={4} style={{ display: "flex" }}>
            <img
              style={{
                width: "370px",
                maxWidth: "90%",
                margin: "0 auto 40px",
              }}
              src={HigherDepositLimits}
              alt="Hiring"
            />
          </Col>
          <Col lg={4} style={{ display: "flex" }}>
            <img
              style={{
                width: "370px",
                maxWidth: "90%",
                margin: "0 auto 40px",
              }}
              src={Ultra}
              alt="Hiring"
            />
          </Col>
        </Row>

        <Row className={`full_width_section ${styles.help_section}`}>
          <Col
            lg={6}
            className={`section_image_container ${styles.image_container}`}
          >
            <img
              className={`image_content ${styles.image_content}`}
              src={supportChatGif}
              alt="Hiring"
            />
          </Col>
          <Col lg={6} className={`${styles.help_text_section}`}>
            <h1 className="heading_text white_text">
              Our support team is
              <br />
              <span className="primary_orange_text">active 24x7</span>
            </h1>
            <p className="paragraph_text light_grey_text">
              Feel free to chat with our support team whenever you need more
              clarity
            </p>
            <ButtonContainer
              buttonText={"Contact Us"}
              path={"/contact"}
              wrapStyle={{
                backgroundColor: "#FBAF03",
                color: "#1c1c1c",
                marginRight: "20px",
                marginTop: "2rem",
                fontSize: "1.5rem",
                fontWeight: "600",
              }}
              activeStatus
            />
          </Col>
        </Row>
        <Row
          className={`section_vertical_spacing text-center light_grey_text ${styles.Testimonial}`}
        >
          <Col xs={12}>
            <h1 className="heading_text white_text">
              Over a <span className="primary_orange_text">million</span> happy
              stories
            </h1>
            <Col xs={12}>
              <Media lessThan="lg">
                <Testimonial type={"Parents"} isTabletView={true} />
              </Media>
            </Col>
            <Col xs={12}>
              <Media greaterThanOrEqual="lg">
                <Testimonial type={"Parents"} isTabletView={false} />
              </Media>
            </Col>
          </Col>
        </Row>
        <Row className="section_vertical_spacing text-center extra_sides_padding">
          <Col xs={12}>
            <h1 className="heading_text white_text">
              We are <span className="primary_orange_text">trusted</span> by
            </h1>
            <Row className={`${styles.trust_section} text-center`}>
              {institutionInvestors.map((investor, index) => (
                <Col
                  xs={6}
                  md={4}
                  lg={3}
                  key={index}
                  className={styles.sponsor_container}
                >
                  <a href={investor[2]}>
                    <img src={investor[1]} alt={investor[0]} />
                  </a>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
        <Row
          className={`full_width_section white_text ${styles.hiring_section}`}
        >
          <Col lg={6} className="section_image_container section_right_image">
            <img
              className={`image_content ${styles.image_content}`}
              src={hiringImage}
              alt="Hiring"
            />
          </Col>
          <Col lg={6} className={`${styles.hiring_text_section}`}>
            <h1 className="heading_text white_text">
              Want to shape finance
              <br />
              for the <span className="primary_orange_text">next gen?</span>
            </h1>
            <Media lessThan="sm">
              <ButtonContainer
                buttonText={"Join the Fam"}
                url={"/careers"}
                wrapStyle={{
                  backgroundColor: "#FBAF03",
                  width: "100%",
                  fontWeight: "600",
                  color: "#1c1c1c",
                }}
                activeStatus
              />
            </Media>
            <Media greaterThanOrEqual="sm">
              <ButtonContainer
                buttonText={"Join the Fam"}
                url={"/careers"}
                wrapStyle={{
                  padding: ".8rem 2.5rem",
                  backgroundColor: "#FBAF03",
                  color: "#1c1c1c",
                  fontSize: "1.2rem",
                  fontWeight: "600",
                }}
                activeStatus
              />
            </Media>
          </Col>
        </Row>

        <QrCode />
        <Footer dark />
      </Container>
    </>
  );
};

export default Home;
