// Modules
import React, { useState, useEffect } from "react";

// Styles
import styles from "./QrCode.module.scss";

// Assets
import { qrcode } from "../../utils/imageProvider";

const QrCode = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const updateScroll = () => {
      setShow(window.scrollY > 60);
    };
    window.addEventListener("scroll", updateScroll);

    return () => {
      window.removeEventListener("scroll", updateScroll);
    };
  }, []);

  return (
    <div
      style={{
        bottom: `${show ? "2rem" : "-20rem"}`,
      }}
      className={`${styles.container}`}
    >
      <div className={`${styles.text}`}>
        <div>Download</div>
        <div>FamApp by Trio</div>
      </div>
      <img
        className={`${styles.qr_code} ${show ? styles.showO : ""}`}
        src={qrcode}
        alt="qrcode"
      />
    </div>
  );
};

export default QrCode;
