// Modules
import React from "react";
import { Row, Col } from "react-bootstrap";

// Assets
import {
  burnFamcoinPhoneImage,
  rewardIcon,
  giveaway,
  challenges,
} from "../../utils/imageProvider";

// Styles
import styles from "./FamCoinsPerks.module.scss";

const content = [
  [
    { text: "Cashback & FamCoins on everyday spends", icon: rewardIcon },
    {
      text: "Enjoy exciting discounts & offers from top brands",
      icon: giveaway,
    },
    { text: "Win big from spinners and giveaways", icon: challenges },
  ],
];

const Section = ({ isTabletView }) => {
  const FeaturesDescription = ({ animate }) => (
    <>
      <div
        className={`${animate ? "animated " : ""} ${
          styles.famcard_description
        }`}
      >
        {content[0].map((item, i) => (
          <div key={i} className={`${styles.fam_perks}`}>
            <img src={item.icon} />
            <div className={`${styles.fam_perks_text}`}>{item.text}</div>
          </div>
        ))}
      </div>
    </>
  );

  if (!isTabletView)
    return (
      <div className={`${styles.famcoin_card}`}>
        <h6 className={`${styles.heading} paragraph_text`}>
          Perks of FamX by Trio
        </h6>
        <>
          <div className={`${styles.divider}`} />
          <FeaturesDescription animate />
        </>
      </div>
    );
  else
    return (
      <>
        <div className={`${styles.famcoin_card}`}>
          <h6 className={`${styles.heading} paragraph_text`}>
            Perks of FamX by Trio
          </h6>
          <>
            <div className={`${styles.divider}`} />
            <FeaturesDescription animate />
          </>
        </div>
      </>
    );
};

const FamCoinsPerks = ({ isTabletView }) => {
  return (
    <div className={`${styles.famcoins_container}`}>
      <Row className={styles.features_container}>
        <Col md={12} lg={7}>
          <Section isTabletView={isTabletView} />
        </Col>
        <Col md={12} lg={5} className={styles.mob_phone_image_container}>
          <img src={burnFamcoinPhoneImage} className={styles.phone_image} />
        </Col>
      </Row>
    </div>
  );
};

export default FamCoinsPerks;
